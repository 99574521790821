import styled, { css } from 'styled-components'
import { cvar, fsvar, fwvar, mediaQueries, svar } from 'styles'

export const containerStyles = () => css`
  ${Wrapper} & {
    --gap: ${svar('6')};

    ${mediaQueries.tabletLandUp} {
      --flex-direction: row;
      --gap: ${svar('20')};
    }
    ${mediaQueries.desktopUp} {
      --gap: ${svar('30')};
    }

    ${mediaQueries.bigDesktopUp} {
      --gap: ${svar('45')};
    }
  }
`

export const ContentWrapper = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('6')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  max-width: var(--max-width);
  font-size: var(--font-size);

  ${mediaQueries.tabletLandUp} {
    order: 2;
    align-self: center;
  }

  ${mediaQueries.desktopUp} {
    --max-width: 39%;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 32%;
  }
`

export const HeadlineWrapper = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('2')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
`

export const ImageWrapper = styled.div`
  --max-width: auto;
  order: 2;
  max-width: var(--max-width);
  ${mediaQueries.tabletLandUp} {
    order: 1;
  }

  ${mediaQueries.desktopUp} {
    --max-width: 40%;
  }
`
export const Intro = styled.p`
  --font-size: auto;
  font-size: var(--font-size);
  font-weight: ${fwvar('bold')};

  ${mediaQueries.bigDesktopUp} {
    --font-size: ${fsvar('md')};
  }
`
export const Text = styled.p`
  --font-size: auto;
  font-size: var(--font-size);

  ${mediaQueries.bigDesktopUp} {
    --font-size: ${fsvar('md')};
  }
`

export const Wrapper = styled.section`
  background-color: ${cvar('background-blue-15')};
`
