import About from 'components/About'
import Contact from 'components/Contact'
import Hero from 'components/Hero'
import PreviousWork from 'components/PreviousWork'
import SEO from 'components/SEO'
import Services from 'components/Services'
import TechReport from 'components/TechReport'
import React from 'react'

export default function HomePage() {
  return (
    <>
      <SEO title="Web Development for Business Owners" />
      <Hero />
      <About />
      <Services />
      <TechReport />
      <PreviousWork />
      <Contact />
    </>
  )
}
