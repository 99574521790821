const techReportContent = {
  headline: 'jumpstart your growth strategy today',
  secondHeadline: `get your free tech report`,
  subHeadline: `Get the technical information to build or transform your website with the best techniques possible. Save time, money and get the best results online. Faster business growth starts here.`,
  bullets: [
    `Tweaks to enhance your design`,
    `Avoid common mistakes`,
    `SEO advice to increase traffic`,
    `Basic improvements to convert more`
  ]
}

export default techReportContent
