import React, { ComponentPropsWithoutRef } from 'react'
import styled, { css, StyledProps } from 'styled-components'
import { cvar, fsvar, shadowvar } from 'styles'
import { CSSPropertiesWithVars, ILinkData } from 'types'
import MyLink from '../MyLink'

export interface IButtonProps extends ComponentPropsWithoutRef<'button'> {
  link?: ILinkData
  backgroundColor?: string
  color?: string
  ghost?: boolean
  alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
  paddingTb?: string
  paddingRl?: string
  shadow?: string
  hoverShadow?: string
}

export default function Button({
  link,
  backgroundColor,
  color,
  ghost,
  alignSelf,
  paddingTb,
  paddingRl,
  shadow,
  hoverShadow,
  ...rest
}: IButtonProps) {
  const styles: CSSPropertiesWithVars = {
    '--background-color': backgroundColor,
    '--color': color,
    '--align-self': alignSelf,
    '--padding-tb': paddingTb,
    '--padding-rl': paddingRl,
    '--shadow': shadow,
    '--hover-shadow': hoverShadow
  }

  if (link) {
    return <StyledLink ghost={ghost} style={styles} link={link} />
  }

  return <StyledButton ghost={ghost} style={styles} {...rest} />
}

interface IButtonStyleProps {
  ghost?: boolean
}

const ghostButton = ({ ghost }: StyledProps<IButtonStyleProps>) => {
  if (ghost) {
    return css`
      border: 1px solid var(--background-color, ${cvar('primary')});
      color: var(--background-color, ${cvar('primary')});
      background-color: transparent;
    `
  }
  return null
}

const Base = css<StyledProps<IButtonStyleProps>>`
  --font-size: ${fsvar('base')};
  color: var(--color, ${cvar('background-white')});
  padding: var(--padding-tb, 8px) var(--padding-rl, 16px);
  border-radius: 100px;
  background: var(--background-color, ${cvar('primary')});
  border: none;
  font-weight: 600;
  font-size: var(--font-size);
  align-self: var(--align-self, flex-start);
  box-shadow: var(--shadow, ${shadowvar('white-low')});
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${ghostButton};

  &:hover {
    box-shadow: var(--hover-shadow, ${shadowvar('white-medium')});
    transform: translateY(-1px);
  }
`

const StyledButton = styled.button`
  ${Base};
`
const StyledLink = styled(MyLink)`
  ${Base};
`
