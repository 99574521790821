import { AiFillCheckCircle } from 'react-icons/ai'
import styled, { css } from 'styled-components'
import { cvar, mediaQueries, svar } from 'styles'

export const Bullets = styled.ul`
  --gap: ${svar('2')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);
`

export const Bullet = styled.li`
  --gap: ${svar('2')};

  display: flex;
  gap: var(--gap);
  align-items: center;
`

export const Check = styled(AiFillCheckCircle)`
  color: ${cvar('primary')};
`
export const ContentContainer = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('10')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  max-width: var(--max-width);
  align-items: var(--align-items);

  ${mediaQueries.tabletUp} {
    --flex-direction: row;
    --gap: ${svar('20')};
  }

  ${mediaQueries.desktopUp} {
    --max-width: 90%;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 70%;
  }
`

export const containerStyles = () => css`
  ${Wrapper} & {
    --gap: ${svar('15')};

    ${mediaQueries.tabletUp} {
      --gap: ${svar('20')};
    }
    ${mediaQueries.desktopUp} {
      align-items: center;
      --gap: ${svar('30')};
    }
  }
`

export const ContentWrapper = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('7')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    --max-width: 50%;
    flex-shrink: 2;
  }
`

export const HeadlineWrapper = styled.div`
  --gap: ${svar('4')};
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`

export const SubHeadline = styled.p`
  ${mediaQueries.tabletUp} {
    width: 90%;
  }
`

export const Wrapper = styled.section`
  background-color: ${cvar('background-yellow-10')};
`
