import Button, { IButtonProps } from 'components/Button'
import Headline from 'components/Headline'
import React from 'react'
import styled, { StyledProps } from 'styled-components'
import { mediaQueries, svar } from 'styles'
import { IFeature } from 'types'

interface IServiceProps extends IButtonProps {
  feature: IFeature
  idx: number
}

export default function Service({ feature, idx, ...rest }: IServiceProps) {
  return (
    <Wrapper>
      <InfoWrapper idx={idx}>
        <Text>
          <Headline size={3}>{feature.title}</Headline>
          <p>{feature.description}</p>
        </Text>
        {feature.link && <Button {...rest} link={feature.link} />}
      </InfoWrapper>
      <ImageWrapper>{feature.image}</ImageWrapper>
    </Wrapper>
  )
}

const Text = styled.div`
  display: flex;
  flex-direction: column;
  --gap: ${svar('4')};

  gap: var(--gap);
`

const ImageWrapper = styled.div`
  --max-width: 100%;

  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    --max-width: 50%;
  }

  ${mediaQueries.tabletLandUp} {
    --max-width: 40%;
  }
`

const InfoWrapper = styled.div<StyledProps<Omit<IServiceProps, 'feature'>>>`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('7')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    order: ${({ idx }) => idx % 2 !== 0 && 1};
  }

  ${mediaQueries.tabletLandUp} {
    --max-width: 45%;
  }
`

const Wrapper = styled.div`
  --display: flex;
  --flex-direction: column;
  --align-items: center;
  --gap: ${svar('10')};
  --max-width: 90%;
  --justify-content: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  align-items: var(--align-items);
  gap: var(--gap);
  max-width: var(--max-width);
  justify-content: var(--justify-content);

  ${mediaQueries.tabletUp} {
    --flex-direction: row;
    --max-width: 100%;
    --gap: ${svar('15')};
  }

  ${mediaQueries.tabletLandUp} {
    --justify-content: center;
    --gap: ${svar('20')};
  }
  ${mediaQueries.desktopUp} {
    --gap: ${svar('25')};
    --max-width: 90%;
  }
  ${mediaQueries.bigDesktopUp} {
    --gap: ${svar('25')};
    --max-width: 80%;
  }
`
