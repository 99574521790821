import Button from 'components/Button'
import Headline from 'components/Headline'
import { StaticImage } from 'gatsby-plugin-image'
import Container from 'layouts/Container'
import React from 'react'
import { cvar } from 'styles'
import { titleCase } from 'title-case'
import heroContent from './heroContent'
import * as S from './styles'

export default function Hero() {
  return (
    <S.Wrapper>
      <Container styles={S.containerStyles}>
        <S.ContentWrapper>
          <div>
            <Headline size={1}>
              {titleCase(heroContent.headline.text1)}
              <S.HeadlineHighlight>
                {titleCase(heroContent.headline.highlight)}
              </S.HeadlineHighlight>
              {titleCase(heroContent.headline.text2)}
            </Headline>
            <S.HeadlineAccent>
              {titleCase(heroContent.headline.accent)}
            </S.HeadlineAccent>
          </div>
          <S.SubHeadline>{heroContent.subHeadline}</S.SubHeadline>
        </S.ContentWrapper>
        <S.ButtonsWrapper>
          <Button
            backgroundColor={cvar('accent-brown')}
            link={{ text: heroContent.CTA, to: '/#contact', isAnchor: true }}
          />

          <Button
            ghost
            backgroundColor={cvar('accent-brown')}
            link={{
              text: heroContent.CTAAlt,
              to: '/#free-tech-report',
              isAnchor: true
            }}
          />
        </S.ButtonsWrapper>

        <S.ImageWrapper>
          <StaticImage
            src="../../assets/images/hero-image-2-min.png"
            alt="Antonio wearing a white collard shirt and blue tie"
            loading="eager"
            placeholder="blurred"
          />
        </S.ImageWrapper>
      </Container>
    </S.Wrapper>
  )
}
