import styled, { css } from 'styled-components'
import { cvar, fsvar, fwvar, mediaQueries, svar } from 'styles'

export const Address = styled.address`
  --gap: ${svar('6')};
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`

export const containerStyles = () => css`
  ${Wrapper} & {
    --align-items: center;
    --gap: ${svar('18')};

    ${mediaQueries.tabletUp} {
      --gap: ${svar('25')};
    }

    ${mediaQueries.desktopUp} {
      --gap: ${svar('35')};
    }
  }
`

export const ContentContainer = styled.div`
  --display: flex;
  --flex-direction: column;
  --gap: ${svar('15')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  max-width: var(--max-width);
  align-items: var(--align-items);

  ${mediaQueries.tabletUp} {
    --flex-direction: row;
    --gap: ${svar('20')};
  }

  ${mediaQueries.desktopUp} {
    --max-width: 90%;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 70%;
  }
`

export const HeadlinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  --gap: ${svar('3')};

  gap: var(--gap);
`

export const Label = styled.p`
  --font-size: ${fsvar('sm')};
  --font-weight: ${fwvar('semibold')};

  font-size: var(--font-size);
  font-weight: var(--font-weight);
`

export const LeftContainer = styled.div`
  --gap: ${svar('6')};

  gap: var(--gap);
  flex-direction: column;
  display: flex;
`

export const RightContainer = styled.div`
  --align-self: flex-start;
  --gap: ${svar('6')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  align-self: var(--align-self);

  ${mediaQueries.tabletUp} {
    --align-self: center;
  }
`

export const Wrapper = styled.section`
  background-color: ${cvar('background-blue-15')};
`
