import styled, { css } from 'styled-components'
import { cvar, fsvar, mediaQueries, svar } from 'styles'

export const ButtonsWrapper = styled.div`
  --gap: ${svar('3')};
  align-self: flex-start;
  display: flex;
  gap: var(--gap);
`

export const containerStyles = () => css`
  ${Wrapper} & {
    --padding-top: ${svar('10')};
    --padding-bottom: ${svar('10')};
    --gap: ${svar('6')};

    ${mediaQueries.tabletUp} {
      --padding-top: ${svar('20')};
      --padding-bottom: ${svar('20')};
      --gap: ${svar('8')};
    }

    ${mediaQueries.desktopUp} {
      --padding-top: ${svar('25')};
    }

    ${mediaQueries.bigDesktopUp} {
      --padding-top: ${svar('35')};
    }
  }
`

export const ContentWrapper = styled.div`
  --gap: ${svar('2')};
  --max-width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    --max-width: 90%;
  }

  ${mediaQueries.tabletLandUp} {
    --max-width: 70%;
  }

  ${mediaQueries.desktopUp} {
    --max-width: 65%;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 944px;
  }
`

export const HeadlineHighlight = styled.span`
  color: ${cvar('accent-brown')};
`
export const HeadlineAccent = styled.p`
  --font-size: 2.2rem;

  font-size: var(--font-size);
  font-weight: 200;

  ${mediaQueries.tabletUp} {
    --font-size: ${fsvar('xl')};
  }

  ${mediaQueries.tabletUp} {
    --font-size: ${fsvar('2xl')};
  }

  ${mediaQueries.bigDesktopUp} {
    --font-size: ${fsvar('3xl')};
  }
`

export const ImageWrapper = styled.div`
  z-index: 99;
  --max-width: 50%;
  --right: 0;
  --bottom: 0;
  --top: 0;

  position: absolute;
  bottom: var(--bottom);
  right: var(--right);
  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    --max-width: 70%;
  }

  ${mediaQueries.tabletLandUp} {
    --max-width: 48%;
  }

  ${mediaQueries.desktopUp} {
    --top: -50px;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 60%;
    --top: -100px;
  }
`
export const SubHeadline = styled.p`
  --font-size: ${fsvar('base')};
  --max-width: 80%;
  font-size: var(--font-size);
  max-width: var(--max-width);

  ${mediaQueries.tabletUp} {
    --max-width: 60%;
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 50%;
  }
`

export const Wrapper = styled.div`
  --min-height: 70vh;
  position: relative;
  isolation: isolate;
  min-height: var(--min-height);

  ${mediaQueries.smPhoneOnly} {
    --min-height: 80vh;
  }

  ${mediaQueries.desktopUp} {
    --min-height: 100vh;
  }
`
