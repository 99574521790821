import { StaticImage } from 'gatsby-plugin-image'
import Container from 'layouts/Container'
import React from 'react'
import Headline from '../Headline'
import aboutContent from './aboutContent'
import * as S from './styles'

export default function About() {
  return (
    <S.Wrapper id="about">
      <Container styles={S.containerStyles}>
        <S.ImageWrapper>
          <StaticImage
            src="../../assets/images/about-min.png"
            alt="Stacked images of Antonio and his wife and son"
            placeholder="blurred"
          />
        </S.ImageWrapper>
        <S.ContentWrapper>
          <S.HeadlineWrapper>
            <S.Intro>{aboutContent.intro}</S.Intro>
            <Headline size={2}>{aboutContent.title}</Headline>
          </S.HeadlineWrapper>
          <S.Text>{aboutContent.text}</S.Text>
        </S.ContentWrapper>
      </Container>
    </S.Wrapper>
  )
}
