import Feature from 'components/Feature'
import Headline from 'components/Headline'
import services from 'data/services'
import Container from 'layouts/Container'
import React from 'react'
import { titleCase } from 'title-case'
import * as S from './styles'

export default function Services() {
  const renderServices = () =>
    services.map((service, idx) => (
      <Feature idx={idx} key={service.title} feature={service} />
    ))

  return (
    <section id="services">
      <Container styles={S.containerStyles}>
        <Headline align="center" size={2}>
          {titleCase(`how I'll help you grow faster`)}
        </Headline>
        <S.ServicesWrapper>{renderServices()}</S.ServicesWrapper>
      </Container>
    </section>
  )
}
