import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { IFeature } from 'types'

const projects: IFeature[] = [
  {
    title: `Krazy Kats Club`,
    description: `KrazyKatsClub is your one place to mint your KrazyKats_NFT and join an amazing group of NFT and crypto enthusiasts.`,
    image: (
      <StaticImage
        src="../assets/images/kkcproject-min.png"
        alt="Krazy Kats Club homepage"
        placeholder="blurred"
      />
    ),
    link: {
      text: `View Project`,
      to: `https://krazykatsclub.com/`,
      isExternal: true
    }
  },
  {
    title: `Lawnr - Landscape Demo`,
    description: `Lawnr is a fictitious company used to demo a custom design for a landscaping company.`,
    image: (
      <StaticImage
        src="../assets/images/lawnr-min.png"
        alt="Lawnr homepage"
        placeholder="blurred"
      />
    ),
    link: {
      text: `View Demo`,
      to: 'https://landscape-demo.pages.dev/',
      isExternal: true
    }
  }
]

export default projects
