import * as yup from 'yup'

export default yup
  .object()
  .shape({
    name: yup
      .string()
      .max(20, 'Must be 20 characters or less.')
      .trim()
      .required('Please enter your name.'),
    email: yup
      .string()
      .email('Please provide a valid email address.')
      .trim()
      .required('Please enter your email address.'),
    subject: yup
      .string()
      .max(75, 'Must be 75 characters or less.')
      .trim()
      .required('Please enter a subject.'),
    message: yup
      .string()
      .max(220, 'Must be 220 characters or less.')
      .trim()
      .required('Please enter a message.')
  })
  .required()
