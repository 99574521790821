import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import Input from 'components/Input'
import encode from 'helpers/encode'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { mediaQueries, shadowvar, svar } from 'styles'
import schema from './schema'

interface ITechReportFormInputs {
  name: string
  email: string
  url: string
}

export default function TechReportForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = useForm<ITechReportFormInputs>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<ITechReportFormInputs> = async (data, e) => {
    e?.preventDefault()
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'tech-report-form', ...data })
      })

      if (isSubmitted) {
        reset()
        toast.success(
          'Thank you for your submission! Check your email for your report in the next few days.'
        )
      }
    } catch (error) {
      toast.error('Something went wrong, please try again.')
    }
  }

  return (
    <Wrapper
      name="tech-report-form"
      method="POST"
      action="#"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Inputs>
        <Input
          shadow={shadowvar('yellow-low')}
          hoverShadow={shadowvar('yellow-medium')}
          placeholder="www.yoursite.com"
          fieldName="url"
          register={register}
          error={errors.url?.message}
          label="Site URL"
          disabled={isSubmitting || isSubmitSuccessful}
        />
        <Input
          shadow={shadowvar('yellow-low')}
          hoverShadow={shadowvar('yellow-medium')}
          placeholder="Enter your name"
          fieldName="name"
          register={register}
          error={errors.name?.message}
          type="text"
          label="Name"
          disabled={isSubmitting || isSubmitSuccessful}
        />
        <Input
          shadow={shadowvar('yellow-low')}
          hoverShadow={shadowvar('yellow-medium')}
          placeholder="Enter your email"
          fieldName="email"
          register={register}
          error={errors.email?.message}
          type="email"
          label="Email"
          disabled={isSubmitting || isSubmitSuccessful}
        />
      </Inputs>
      <Button
        shadow={shadowvar('yellow-low')}
        hoverShadow={shadowvar('yellow-medium')}
        paddingTb={svar('3')}
        type="submit"
        disabled={isSubmitting || isSubmitSuccessful}
      >
        Get Your Report
      </Button>
    </Wrapper>
  )
}

const Inputs = styled.div`
  --gap: ${svar('4')};
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  ${mediaQueries.tabletUp} {
    --gap: ${svar('5')};
  }
`
const Wrapper = styled.form`
  --gap: ${svar('7')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  flex: 1;
  flex-shrink: 0;

  ${mediaQueries.tabletUp} {
    justify-content: space-between;
  }
`
