import React, { ComponentPropsWithoutRef } from 'react'
import { UseFormRegister } from 'react-hook-form'
import styled, { css, StyledProps } from 'styled-components'
import { cvar, fsvar, fwvar, shadowvar, svar } from 'styles'
import { CSSPropertiesWithVars } from 'types'
import { mediaQueries } from '../../styles/mediaQueries'

interface IInputProps extends ComponentPropsWithoutRef<'input'> {
  fieldName: string
  register: UseFormRegister<any>
  error?: string
  label?: string
  isTextArea?: boolean
  shadow?: string
  hoverShadow?: string
}

export default function Input({
  fieldName,
  register,
  error,
  label,
  isTextArea,
  shadow,
  hoverShadow,
  ...rest
}: IInputProps) {
  const inputStyles: CSSPropertiesWithVars = {
    '--shadow': shadow,
    '--hover-shadow': hoverShadow
  }
  const Tag = isTextArea ? 'textarea' : 'input'

  return (
    <Wrapper>
      {label && <Label htmlFor={fieldName}>{label}</Label>}
      <StyledInput
        isTextArea={isTextArea}
        style={inputStyles}
        as={Tag}
        {...rest}
        aria-invalid={error ? 'true' : 'false'}
        id={fieldName}
        {...register(fieldName)}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

const Error = styled.p`
  color: ${cvar('error')};
`

const Label = styled.label`
  --font-size: ${fsvar('sm')};
  --font-weight: ${fwvar('semibold')};

  font-size: var(--font-size);
  font-weight: var(--font-weight);
`

const StyledInput = styled.input<StyledProps<Pick<IInputProps, 'isTextArea'>>>`
  --padding: ${svar('3')};

  resize: none;
  padding: var(--padding);
  border-radius: 5px;
  border: 1px solid ${cvar('primary')};
  color: ${cvar('text-charcoal')};
  box-shadow: var(--shadow, ${shadowvar('white-low')});
  transition: all 0.2s ease-in-out;

  ${({ isTextArea }) =>
    isTextArea &&
    css`
      --min-height: 100px;

      min-height: var(--min-height);

      ${mediaQueries.desktopUp} {
        --min-height: 150px;
      }
    `}

  &:focus {
    box-shadow: var(--hover-shadow, ${shadowvar('white-medium')});
    transform: translateY(-1px);
    outline: none;
  }
`

const Wrapper = styled.div`
  --gap: ${svar('1')};
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  flex: 1;
`
