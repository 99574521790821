import * as yup from 'yup'

const URL =
  /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export default yup
  .object()
  .shape({
    name: yup
      .string()
      .max(20, 'Must be 20 characters or less.')
      .trim()
      .required('Please enter your name.'),
    email: yup
      .string()
      .email('Please provide a valid email address.')
      .trim()
      .required('Please enter your email address.'),
    url: yup.string().matches(URL, 'Please enter a valid url.').required()
  })
  .required()
