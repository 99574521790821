import Feature from 'components/Feature'
import Headline from 'components/Headline'
import projects from 'data/projects'
import Container from 'layouts/Container'
import React from 'react'
import styled, { css } from 'styled-components'
import { mediaQueries, svar } from 'styles'

export default function PreviousWork() {
  const renderProjects = () =>
    projects.map((project, idx) => (
      <Feature ghost key={project.title} idx={idx} feature={project} />
    ))

  return (
    <section id="experience">
      <Container styles={containerStyles}>
        <Headline align="center" size={2}>
          My Previous Work
        </Headline>
        <ProjectsContainer>{renderProjects()}</ProjectsContainer>
      </Container>
    </section>
  )
}

const containerStyles = () => css`
  --align-items: center;
  --gap: ${svar('18')};

  ${mediaQueries.tabletUp} {
    --gap: ${svar('25')};
  }

  ${mediaQueries.desktopUp} {
    --gap: ${svar('35')};
  }
`

export const ProjectsContainer = styled.div`
  --flex-direction: column;
  --display: flex;
  --align-items: center;
  --gap: ${svar('25')};
  --max-width: auto;

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  align-items: var(--align-items);
  max-width: var(--max-width);

  ${mediaQueries.tabletLandUp} {
    --gap: ${svar('35')};
  }

  ${mediaQueries.bigDesktopUp} {
    --max-width: 80%;
  }
`
