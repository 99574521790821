import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import Input from 'components/Input'
import encode from 'helpers/encode'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { cvar, mediaQueries, shadowvar, svar } from 'styles'
import schema from './schema'

interface IContactFormInputs {
  name: string
  email: string
  subject: string
  message: string
}

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitted, isSubmitSuccessful }
  } = useForm<IContactFormInputs>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<IContactFormInputs> = async (data, e) => {
    e?.preventDefault()
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact-form', ...data })
      })

      if (isSubmitted) {
        reset()
        toast.success(
          'Thank you for your submission! I will get back to you soon.'
        )
      }
    } catch (error) {
      toast.error('Something went wrong, please try again.')
    }
  }

  return (
    <Wrapper
      name="contact-form"
      method="POST"
      action="#"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Inputs>
        <NameEmail>
          <Input
            shadow={shadowvar('blue-low')}
            hoverShadow={shadowvar('blue-medium')}
            fieldName="name"
            register={register}
            error={errors.name?.message}
            type="text"
            label="Name"
            placeholder="Enter your name"
            disabled={isSubmitting || isSubmitSuccessful}
          />
          <Input
            shadow={shadowvar('blue-low')}
            hoverShadow={shadowvar('blue-medium')}
            fieldName="email"
            register={register}
            error={errors.email?.message}
            type="email"
            label="Email"
            placeholder="Enter your email"
            disabled={isSubmitting || isSubmitSuccessful}
          />
        </NameEmail>
        <Input
          shadow={shadowvar('blue-low')}
          hoverShadow={shadowvar('blue-medium')}
          fieldName="subject"
          register={register}
          error={errors.subject?.message}
          type="text"
          label="Subject"
          placeholder="Subject"
          disabled={isSubmitting || isSubmitSuccessful}
        />
        <Input
          shadow={shadowvar('blue-low')}
          hoverShadow={shadowvar('blue-medium')}
          isTextArea
          fieldName="message"
          register={register}
          error={errors.message?.message}
          type="text"
          label="Message"
          placeholder="Your message..."
          disabled={isSubmitting || isSubmitSuccessful}
        />
      </Inputs>
      <Button
        backgroundColor={cvar('accent-yellow')}
        color={cvar('primary')}
        shadow={shadowvar('blue-low')}
        hoverShadow={shadowvar('blue-medium')}
        paddingTb={svar('3')}
        type="submit"
        disabled={isSubmitting || isSubmitSuccessful}
      >
        Send Message
      </Button>
    </Wrapper>
  )
}

const Inputs = styled.div`
  --gap: ${svar('4')};
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  ${mediaQueries.tabletUp} {
    --gap: ${svar('5')};
  }
`

const NameEmail = styled.div`
  --gap: ${svar('4')};
  --flex-direction: column;

  display: flex;
  flex-direction: var(--flex-direction);
  gap: var(--gap);

  ${mediaQueries.tabletUp} {
    --flex-direction: row;
    justify-content: space-between;
  }
`

const Wrapper = styled.form`
  --gap: ${svar('7')};

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  flex: 1;
  flex-shrink: 0;

  ${mediaQueries.tabletUp} {
    justify-content: space-between;
  }
`
