import ContactForm from 'components/ContactForm'
import Headline from 'components/Headline'
import SocialIcons from 'components/SocialIcons'
import { useSiteMeta } from 'hooks/useSiteMeta'
import Container from 'layouts/Container'
import React from 'react'
import { cvar } from 'styles'
import { titleCase } from 'title-case'
import * as S from './styles'

export default function Contact() {
  const { address, email } = useSiteMeta()
  return (
    <S.Wrapper id="contact">
      <Container styles={S.containerStyles}>
        <Headline size={2}>Let's Get Started Today</Headline>
        <S.ContentContainer>
          <S.LeftContainer>
            <S.HeadlinesContainer>
              <Headline size={3}>{titleCase('get in touch')}</Headline>
              <p>
                It's time for a change. Contact me to discuss how I can help
                grow your business faster.
              </p>
            </S.HeadlinesContainer>
            <ContactForm />
          </S.LeftContainer>
          <S.RightContainer>
            <S.Address>
              <div>
                <S.Label>Email Address</S.Label>
                <p>{email}</p>
              </div>
              <div>
                <S.Label>Address</S.Label>
                <p>{address}</p>
              </div>
            </S.Address>
            <SocialIcons color={cvar('text-electric-blue')} />
          </S.RightContainer>
        </S.ContentContainer>
      </Container>
    </S.Wrapper>
  )
}
