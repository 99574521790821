import styled, { css } from 'styled-components'
import { mediaQueries, svar } from 'styles'

export const containerStyles = () => css`
  --align-items: center;
  --gap: ${svar('18')};

  ${mediaQueries.tabletUp} {
    --gap: ${svar('25')};
  }

  ${mediaQueries.desktopUp} {
    --gap: ${svar('35')};
  }
`
export const ServicesWrapper = styled.div`
  --flex-direction: column;
  --display: flex;
  --align-items: center;
  --gap: ${svar('25')};

  display: var(--display);
  flex-direction: var(--flex-direction);
  gap: var(--gap);
  align-items: var(--align-items);

  ${mediaQueries.tabletLandUp} {
    --gap: ${svar('35')};
  }
`
