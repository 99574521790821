import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { IFeature } from 'types'

const services: IFeature[] = [
  {
    title: `Website Design`,
    description: `Gain your customer's trust by showing them who you are with a design that backs your brand.`,
    image: (
      <StaticImage
        src="../assets/images/design-min.png"
        alt="Image collage of wireframe design"
        placeholder="blurred"
      />
    )
  },
  {
    title: `Webflow Development`,
    description: `Take control of your content with a few clicks, minus the stress of technology overload. Save your time and leave the headaches to me.`,
    image: (
      <StaticImage
        src="../assets/images/webflow-min.png"
        alt="Woman sitting at a table with a laptop with a window in the background"
        placeholder="blurred"
      />
    )
  },
  {
    title: `Frontend Development`,
    description: `Take 100% control of the experience with custom features for your customers. Engage with them how you want when you want.`,
    image: (
      <StaticImage
        src="../assets/images/frontend-min.png"
        alt="A man sitting at a laptop with headphones on and a window in the background"
        placeholder="blurred"
      />
    )
  },
  {
    title: `Responsive Design`,
    description: `New customers reach your site on mobile devices over 50% of their visits. Make sure you look good every time.`,
    image: (
      <StaticImage
        src="../assets/images/responsive-min.png"
        alt="A man looking at a cellphone sitting on a windowsill outside a building"
        placeholder="blurred"
      />
    )
  },
  {
    title: `SEO Optimization`,
    description: `Google process nearly 63,000 searches every second. Maximize your business growth by making sure you rank high on the return lists.`,
    image: (
      <StaticImage
        src="../assets/images/seo-opt-min.png"
        alt="A person sitting at a table with a tea cup and a laptop with google search on the screen"
        placeholder="blurred"
      />
    )
  },
  {
    title: `Content Management`,
    description: `Only 20% of website visitors read past the headline. Grow your business faster by keeping the other 80% on the page with the content they value.`,
    image: (
      <StaticImage
        src="../assets/images/content-min.png"
        alt="A person sitting at a laptop with Canva open to a project"
        placeholder="blurred"
      />
    )
  }
]

export default services
