const heroContent = {
  headline: {
    text1: `simplifying `,
    text2: ` for small businesses`,
    highlight: `web development`,
    accent: `Because why Do it alone`
  },
  subHeadline: `It's time for a change. I'll focus on growing your business so you can focus on closing the deal.`,
  CTA: `Get In Touch`,
  CTAAlt: `Free Tech Report`
}

export default heroContent
