import Container from 'layouts/Container'
import React from 'react'
import { cvar } from 'styles'
import { titleCase } from 'title-case'
import Headline from '../Headline/index'
import TechReportForm from '../TechReportForm/index'
import * as S from './styles'
import techReportContent from './techReportContent'

export default function TechReport() {
  return (
    <S.Wrapper id="free-tech-report">
      <Container styles={S.containerStyles}>
        <Headline color={cvar('primary')} align="center" size={2}>
          {titleCase(techReportContent.headline)}
        </Headline>
        <S.ContentContainer>
          <S.ContentWrapper>
            <S.HeadlineWrapper>
              <Headline color={cvar('primary')} size={3}>
                {titleCase(techReportContent.secondHeadline)}
              </Headline>
              <S.SubHeadline>{techReportContent.subHeadline}</S.SubHeadline>
            </S.HeadlineWrapper>
            <S.Bullets>
              {techReportContent.bullets.map(bullet => (
                <S.Bullet key={bullet}>
                  <S.Check />
                  {bullet}
                </S.Bullet>
              ))}
            </S.Bullets>
          </S.ContentWrapper>
          <TechReportForm />
        </S.ContentContainer>
      </Container>
    </S.Wrapper>
  )
}
